@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Questrial:wght@200;300;400;600;700;800;900&display=swap');
/* @import 'antd/dist/antd.css'; */

body {
  font-family: 'Poppins', sans-serif;
  background-color: black;
}
body .head {
  font-family: 'Questrial', sans-serif;
}

body .section {
  font-family: 'Work Sans', sans-serif;
  @apply text-4xl sm:text-6xl capitalize mb-1 font-bold tracking-wider text-transparent bg-clip-text bg-gradient-to-tr from-red-400 to-blue-600;
}
.text-stroke {
  -webkit-text-stroke: 1px white;
  position: relative;
}
.main {
  @apply text-[56px] whitespace-nowrap font-black text-[#000000] text-stroke tracking-wider uppercase;
}
input[type='text']:valid ~ .input-text,
input[type='text']:focus ~ .input-text {
  @apply text-white bg-black rounded opacity-100 transform -translate-x-2 -translate-y-8 scale-50;
}

textarea[type='text']:valid ~ .input-text,
textarea[type='text']:focus ~ .input-text {
  @apply text-white bg-black rounded opacity-100 transform -translate-x-6 -translate-y-4 scale-50;
}

input[type='email']:valid ~ .input-email,
input[type='email']:focus ~ .input-email {
  @apply text-white bg-black rounded opacity-100 transform -translate-x-2 -translate-y-8 scale-50;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
  @apply uppercase relative inline-block cursor-pointer;
}
.btn__text {
  @apply block text-center text-[16px] uppercase font-medium tracking-wider leading-none bg-gradient-to-tr from-blue-600 to-violet-500 text-white transition-all duration-200 ease-linear top-0 right-0 py-3 px-3 sm:py-4 sm:px-4 relative;
}
.btn::before {
  @apply content-[""] block absolute top-[5px] -right-[5px]  w-full h-full bg-white border border-blue-600 duration-200 ease-linear;
}
.btn:hover::before {
  @apply top-[4px] -right-[4px];
}
.btn__text:hover {
  @apply top-[2px] -right-[2px];
}
.btn:active::before,
.btn__text:active {
  @apply top-[3px] -right-[3px];
}
